let fetchInstance;

/**
 * Fetch data from backend api
 * @description Use it in store actions
 * @description Use store actions in useAsyncData
 * @description Provide SERVER_URL and CLIENT_URL env variables
 * @param {string} url request url after api prefix
 * @param {object} [options] request options (query, body, headers etc.)
 * @param {string} [apiVersion] api url version prefix e.g. 'v1'
 * @returns {Promise<any>} request promise
 * @example
 * useRequest('/route').then((data) => console.log(data));
 * @see https://nuxt.com/docs/api/utils/dollarfetch
 * @see https://nuxt.com/docs/api/composables/use-async-data
 * @see https://pinia.vuejs.org/ssr/nuxt.html
 */
export const useRequest = (url, options = {}, apiVersion = 'v1') => {
  // creating instance only if it doesn't exist
  if (!fetchInstance) {
    const {
      app: { baseURL },
    } = useRuntimeConfig();

    // configuring url
    const apiPrefix = 'rest';

    let baseUrl = baseURL === '/' ? '' : baseURL;

    if (import.meta.server) {
      // server url
      const { serverUrl } = useRuntimeConfig();
      baseUrl = serverUrl;
    }

    fetchInstance = $fetch.create({
      baseURL: `${baseUrl}/${apiPrefix}`,
      onRequest({ options }) {
        const headers = {};

        try {
          const { locale } = useI18n();
          if (locale.value) headers['X-Language'] = locale.value;
        } catch (error) {
          try {
            const nuxtApp = useNuxtApp();
            headers['X-Language'] = nuxtApp.$i18n.locale.value;
          } catch (error) {
            // ignore
          }
        }

        options.headers = { ...headers, ...options.headers };
      },
    });
  }

  return fetchInstance(`/${apiVersion}${url}`, options);
};
