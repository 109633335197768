const analytics = {
  yandex: {
    noscript: (id) =>
      `<div><img src="https://mc.yandex.ru/watch/${id}" style="position:absolute; left:-9999px;" alt="" /></div>`,
    script: (id) => `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(${id}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   });
`,
  },
};

export default defineNuxtPlugin({
  name: 'analytics',
  dependsOn: ['locale-detect'],
  parallel: true,
  async setup(nuxtApp) {
    const {
      public: { yandexMetricaIds },
    } = useRuntimeConfig();
    const locale = nuxtApp.$i18n.locale.value;

    const yandexMetricaId = yandexMetricaIds[locale];
    if (!yandexMetricaId) return;

    if (import.meta.server) {
      // add noscript on server
      useHead({
        noscript: [
          {
            innerHTML: analytics.yandex.noscript(yandexMetricaId),
            tagPosition: 'bodyOpen',
          },
        ],
      });
    } else {
      // add script on client
      useHead({
        script: [
          {
            innerHTML: analytics.yandex.script(yandexMetricaId),
            tagPosition: 'head',
          },
        ],
      });
    }
  },
});
