<script setup>
import { ModalsContainer, useModal } from 'vue-final-modal';
import { COOKIES_AGREE_KEY } from '@/constants/cookies';

// cookies accept
const cookiesModal = useModal({
  component: resolveComponent('LazyModalsCookies'),
  attrs: {
    onClosed() {
      const cookiesCookie = useCookie(COOKIES_AGREE_KEY);
      cookiesCookie.value = 'true';
    },
  },
});
onMounted(() => {
  const cookiesCookie = useCookie(COOKIES_AGREE_KEY);
  if (!cookiesCookie.value) cookiesModal.open();
});
</script>

<template>
  <div id="app" class="app">
    <NuxtLayout v-slot="{ className }" class="app__layout">
      <NuxtPage :class="className" />
    </NuxtLayout>

    <ClientOnly>
      <Teleport to="body">
        <ModalsContainer />
      </Teleport>

      <template #fallback />
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
.app {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__layout) {
    flex-grow: 1;
  }
}
</style>
