<script setup>
const modifierClass = useGridModifier();
</script>

<template>
  <div
    class="ui-grid-columns-base"
    :class="`ui-grid-columns-base--${modifierClass}`"
  >
    <slot name="1" />
    <slot name="2" />
    <slot name="3" />
  </div>
</template>

<style scoped lang="scss">
.ui-grid-columns-base {
  @include media-breakpoint-up(sm) {
    @include grid-columns-base;
  }

  $grid-data: map-get(map-get($grids, default), base);

  @each $type, $area in map-get($grid-data, template-areas) {
    &--#{$type} {
      @include media-breakpoint-up(sm) {
        @include grid-columns($area-id: $type);
      }
    }
  }
}
</style>
