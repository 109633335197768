<script setup>
import { ModalsContainer } from 'vue-final-modal';

const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const errorTranslationKeys = {
  default: 'default',
  404: '404',
};

const errorConfig = computed(() => {
  const { statusCode } = props.error;

  return {
    code: statusCode,
    title: t(
      `errors.codes.${errorTranslationKeys[statusCode] || errorTranslationKeys.default}`,
    ),
  };
});
</script>

<template>
  <div id="app" class="error">
    <NuxtLayout v-slot="{ className }" class="error__layout">
      <div class="error__view" :class="className">
        <UiGridColumnsBase class="container">
          <template #2>
            <div class="error__wrapper">
              <div class="error__code">
                <template v-if="errorConfig.code === 404">
                  <span class="error__font error__font--code">4</span>

                  <img
                    class="error__image error__image--404"
                    src="/images/404.svg"
                    alt="404"
                  />

                  <span class="error__font error__font--code">4</span>
                </template>

                <span v-else class="error__font error__font--code">
                  {{ errorConfig.code }}
                </span>
              </div>

              <div class="error__title">
                <span class="error__font error__font--title">
                  {{ errorConfig.title }}
                </span>
              </div>

              <NuxtLink to="/" class="error__link link">
                <span class="error__font error__font--link">
                  {{ $t('errors.goToMain') }}
                </span>
              </NuxtLink>
            </div>
          </template>
        </UiGridColumnsBase>
      </div>
    </NuxtLayout>

    <ModalsContainer />
  </div>
</template>

<style scoped lang="scss">
.error {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__layout) {
    flex-grow: 1;
  }

  &__view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: em(300);
    padding-bottom: em(100);

    @include media-breakpoint-down(sm) {
      min-height: em(236);
      padding-bottom: em(30);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__code {
    display: flex;
    align-items: center;
    gap: em(5);
    color: $color-red-1;

    @include media-breakpoint-down(sm) {
      gap: em(3);
    }
  }

  &__font {
    &--code {
      font-size: em(128);
      line-height: em(160, 128);

      @include media-breakpoint-down(sm) {
        font-size: em(40);
        line-height: 1;
      }
    }

    &--title {
      font-size: em(48);
      line-height: 1;

      @include media-breakpoint-down(sm) {
        font-size: em(20);
        line-height: em(22, 20);
      }
    }

    &--link {
      font-size: em(32);
      line-height: 1;

      @include media-breakpoint-down(sm) {
        font-size: em(20);
      }
    }
  }

  &__title {
    margin-top: em(41);

    @include media-breakpoint-down(sm) {
      margin-top: em(27);
    }
  }

  &__link {
    margin-top: em(31);

    @include media-breakpoint-down(sm) {
      margin-top: em(13);
    }
  }

  &__image {
    &--404 {
      width: em(92);
      height: em(87);

      @include media-breakpoint-down(sm) {
        width: em(29);
        height: em(27);
      }
    }
  }
}
</style>
