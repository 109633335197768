import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDown, LazySvgoArrowLeft, LazySvgoAttachment, LazySvgoCheckCircle, LazySvgoCheck, LazySvgoClose, LazySvgoCookie, LazySvgoCopy, LazySvgoLinkArrowDown, LazySvgoLinkArrowRight, LazySvgoReset, LazySvgoSearch, LazySvgoSimpleArrowRight, LazySvgoSocialsOk, LazySvgoSocialsTelegram, LazySvgoSocialsVk, LazySvgoSocialsWhatsapp } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoAttachment", LazySvgoAttachment],
["SvgoCheckCircle", LazySvgoCheckCircle],
["SvgoCheck", LazySvgoCheck],
["SvgoClose", LazySvgoClose],
["SvgoCookie", LazySvgoCookie],
["SvgoCopy", LazySvgoCopy],
["SvgoLinkArrowDown", LazySvgoLinkArrowDown],
["SvgoLinkArrowRight", LazySvgoLinkArrowRight],
["SvgoReset", LazySvgoReset],
["SvgoSearch", LazySvgoSearch],
["SvgoSimpleArrowRight", LazySvgoSimpleArrowRight],
["SvgoSocialsOk", LazySvgoSocialsOk],
["SvgoSocialsTelegram", LazySvgoSocialsTelegram],
["SvgoSocialsVk", LazySvgoSocialsVk],
["SvgoSocialsWhatsapp", LazySvgoSocialsWhatsapp],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
