const phoneAdapter = (data) => {
  return {
    text: data.text,
    value: data.value,
  };
};

const socialsAdapter = (data) => {
  return {
    ok: data.ok?.link,
    tg: data.tg?.link,
    vk: data.vk?.link,
  };
};

export const settingsAdapter = (data) => {
  const { common = {}, socials = {} } = data;
  const { copyright, phones = [] } = common;
  const { mir, sbp } = socials;

  const phonesArray = !Array.isArray(phones) ? Object.values(phones) : phones;

  return {
    common: {
      copyright,
      phones: phonesArray.map(phoneAdapter),
    },

    socials: {
      mir: mir && socialsAdapter(mir),
      sbp: sbp && socialsAdapter(sbp),
    },
  };
};
