import { settingsAdapter } from '@/adapters/settings';

export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    isSmoothScroll: false,
    headerVisible: true,
    settings: null,
  }),
  actions: {
    setIsSmoothScroll(value) {
      this.isSmoothScroll = value;
    },
    setHeaderVisible(value) {
      this.headerVisible = value;
    },
    fetchSettings() {
      return useRequest('/settings').then((data) => {
        this.settings = settingsAdapter(data);

        return this.settings;
      });
    },
  },
});
