export const useGridModifier = () => {
  const slots = useSlots();

  const modifier = ref();

  function setModifierValue() {
    modifier.value = Object.keys(slots).join('');
  }

  setModifierValue();
  onUpdated(setModifierValue);

  return modifier;
};
