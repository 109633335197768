import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const wrapperSelector = '.ScrollSmoother-wrapper';
const bodyScrollClasses = {
  false: 'scroll-native',
  true: 'scroll-smooth',
};

function createScrollSmoother(ScrollSmoother) {
  return ScrollSmoother.create({
    content: '#__nuxt',
    smooth: 1,
    // effects: true,
    ignoreMobileResize: true,
    smoothTouch: true, // for ScrollSmoother to react on mobile->desktop resize
  });
}

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    const globalStore = useGlobalStore();
    const { ScrollSmoother } = window;
    const { body } = document;

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    // create scroll smoother
    let scrollSmoother = createScrollSmoother(ScrollSmoother);
    let touchOnlyState = checkIsTouchOnly();

    // kill on touch-only devices
    if (touchOnlyState) {
      scrollSmoother.kill();
    }

    globalStore.setIsSmoothScroll(!touchOnlyState);

    // watch window resize
    useEventListener('resize', () => {
      const isTouchOnly = checkIsTouchOnly();
      if (touchOnlyState === isTouchOnly) return;

      touchOnlyState = isTouchOnly;

      // trigger by touch-only condition
      if (touchOnlyState) {
        scrollSmoother.kill();
      } else {
        scrollSmoother = createScrollSmoother(ScrollSmoother);
      }

      globalStore.setIsSmoothScroll(!touchOnlyState);
    });

    // switch body scroll class
    watch(
      () => globalStore.isSmoothScroll,
      (isSmoothScroll) => {
        body.classList.add(bodyScrollClasses[isSmoothScroll]);
        body.classList.remove(bodyScrollClasses[!isSmoothScroll]);
      },
      { immediate: true },
    );

    // fix scroll smoother on routing
    useNuxtApp().hook('page:finish', () => {
      ScrollTrigger.refresh();
    });

    // observe body padding-right (for scroll-lock with reserved gaps)
    const wrapper = document.querySelector(wrapperSelector);

    const observer = new MutationObserver(() => {
      const header = document.querySelector('[data-layout-header]');

      const bodyPaddingRight = body.style.paddingRight;

      gsap.set(wrapper, { paddingRight: bodyPaddingRight });
      if (header) gsap.set(header, { marginRight: bodyPaddingRight });
    });

    observer.observe(body, { attributes: true });
  },
});
