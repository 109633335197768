import { ScrollTrigger } from 'gsap/ScrollTrigger';

// common
/**
 * Get value with boundaries
 * @param {number} value desired value
 * @param {number} min min boundary
 * @param {number} max max boundary
 * @returns {number} calculated value
 * @example
 * minMax(15, 5, 10); // returns 10 (max boundary)
 */
export function minMax(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

/**
 * Simple object clone
 * @param {object} object object to clone
 * @returns {any} cloned object
 * @example
 * const clonedObject = cloneObject(originalObject);
 */
export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

// promise-based utils
/**
 * Await timeout
 * @param {number} [duration] timeout duration
 * @returns {Promise<void>} timeout promise
 * @example
 * await awaitTimeout(100);
 */
export function awaitTimeout(duration = 0) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

/**
 * Await request animation frame
 * @returns {Promise<void>} request animation frame promise
 * @example
 * await awaitRAF();
 */
export function awaitRAF() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

/**
 * Await double request animation frame
 * @returns {Promise<void>} double request animation frame promise
 * @example
 * await awaitDoubleRAF();
 */
export async function awaitDoubleRAF() {
  await awaitRAF();
  await awaitRAF();
}

// em font-sizing
/**
 * Get element font size
 * @param {HTMLElement|string} [element] reference element or selector
 * @returns {number} element font-size
 */
export function getElementFz(element = document.body) {
  if (typeof element === 'string') {
    element = document.querySelector(element);
  }

  if (!element) return 0;

  const elementStyle = window.getComputedStyle(element);
  return parseFloat(elementStyle.fontSize);
}

/**
 * Get px value regarding element font-size
 * @param {number} pxValue desired px value
 * @param {HTMLElement|string} [contextElement] reference element or selector
 * @returns {number} calculated px value
 */
export function toResizedPx(pxValue, contextElement = document.body) {
  return (pxValue / 16) * getElementFz(contextElement);
}

/**
 * Get em value regarding element font-size
 * @param {number} pxValue desired px value
 * @param {number} [pxContext] px context
 * @returns {number} calculated px value
 */
export function toResizedEm(pxValue, pxContext = 16) {
  return pxValue / pxContext;
}

// preload images
/**
 * Preload image
 * @param {string} url image url
 * @returns {Promise<void>} upload promise
 */
export function preloadImage(url) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;

    image.addEventListener('load', resolve);
    image.addEventListener('error', resolve);
  });
}

/**
 * Preload images
 * @param {string[]} imageUrls image urls
 * @returns {Promise<Awaited<void>[]>} upload promises
 */
export function preloadImages(imageUrls = []) {
  const preloads = Array.from(imageUrls).map(preloadImage);

  return Promise.all(preloads);
}

// preload fonts
/**
 * Get font preload list
 * @param {string} path font path
 * @param {number[]} weights font weights
 * @param {string} baseUrl site base url
 * @returns {{rel: string, href: string, as: string, type: string, crossorigin: true}[]} font preload list
 */
export function getFontPreloadList({ path, weights }, baseUrl = '/') {
  return weights.map((weight) => ({
    rel: 'preload',
    href: `${baseUrl}fonts/${path}${weight}.woff2`,
    as: 'font',
    type: 'font/woff2',
    crossorigin: true,
  }));
}

/**
 *
 * @param {object} fontsList font params list
 * @param {string} fontsList.path font path
 * @param {number[]} fontsList.weights font path
 * @param {string} [baseUrl] site base url
 * @returns {{rel: string, href: string, as: string, type: string, crossorigin: true}[]} fonts preload list
 */
export function getFontsPreloadList(fontsList, baseUrl = '/') {
  return fontsList.reduce(
    (result, { path, weights }) => [
      ...result,
      ...getFontPreloadList({ path, weights }, baseUrl),
    ],
    [],
  );
}

// device type
export function checkIsTouchOnly() {
  return window.matchMedia('(pointer: coarse)').matches;
}

// adapters
export async function getSectionAdapters(sections, adapters) {
  const sectionAdapterImports = sections.map(async (section) => {
    const {
      type,
      data: { id },
    } = section;

    const adapterName = adapters[type];
    if (adapterName === false) return { type, id };

    try {
      const adapterImport = await import(
        `@/adapters/constructor/${adapterName}.js`
      );

      try {
        const data = await adapterImport.default(section, sections);

        return {
          type,
          data,
          id,
        };
      } catch (error) {
        return {
          type,
          error: `adapter error: ${error.toString()}`,
        };
      }
    } catch (error) {
      return {
        type,
        error: 'missing adapter',
      };
    }
  });

  return Promise.all(sectionAdapterImports);
}
export function filterAdaptedSections(sections, components, source) {
  return sections?.filter(({ error, type }) => {
    if (error) {
      if (process.client) {
        console.warn(`${source}: "${type}": ${error}`);
      }
      return false;
    }

    if (!components[type]) {
      if (process.client) {
        console.warn(`${source}: "${type}": missing component`);
      }
      return false;
    }

    return true;
  });
}

// scroll
export async function safeScrollToElement(element, refresh = true) {
  const documentStyles = getComputedStyle(document.documentElement);
  const scrollOffset = parseFloat(documentStyles.scrollPaddingTop);

  window.scrollTo(0, element.offsetTop - scrollOffset);

  if (refresh) {
    await awaitDoubleRAF();
    ScrollTrigger.refresh();
  }
}

// animation
export function getOffsetForPin(
  progress,
  durationHeight,
  headerHeight,
  headerBottom,
) {
  // calculate offset
  const offsetByProgress =
    durationHeight * progress - headerHeight * (1 - progress) + headerBottom;

  // set boundaries for offset
  return Math.max(0, Math.min(durationHeight, offsetByProgress));
}

// form
export function getFormState(isFormSubmitting, isFormSubmitted) {
  switch (true) {
    case isFormSubmitting:
      return 'submitting';
    case isFormSubmitted:
      return 'submitted';
    default:
      return 'static';
  }
}
