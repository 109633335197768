export function cardsMirScrollToTopHandler(to, from) {
  const tabs = [
    '/cards-mir/security',
    '/cards-mir/security/security-program',
    '/cards-mir/security/standart-pcidss',
    '/cards-mir/security/standart-pcidss/self-assessment',
    '/cards-mir/security/security-program/program-tsp',
    '/cards-mir/security/security-program/after-hack',
    '/cards-mir/security/about-fraud',
  ];

  return !tabs.includes(from.path);
}
